@import "../../../ui/_variables";

.page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 35px;
  // align-items: center;

  .filter-wrapper.dropper-shopper {
    display: flex;
    align-items: center;
    gap: 10px;
    // background: $landing-page-bg;
    margin-bottom: 24px;

    .ongoing-filter {
      padding: 12px 24px;
      text-align: center;
      border: 1px solid #d3d4d8;
      border-radius: 90px;
      color: #5c5c5c;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;

      &.active {
        background: #000;
        color: white;
      }
    }
  }

  .orders-column-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;

    .order-column-wrapper {
      display: flex;
      max-width: 400px;
      width: 100%;
      // width: fit-content;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px;
      background-color: #FFFFFF;
      border-radius: 15px;
      cursor: pointer;

      .order-left-side {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          font-weight: bolder;
          font-size: 14px;
        }
        .status {
          font-weight: 500;
          font-size: 12px;
          color: #fd5c1d;
        }
        .date {
          font-weight: lighter;
          font-size: 12px;
          color: #959595;
        }
      }
      .order-right-side {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: end;

        .store {
          font-weight: 400;
          font-size: 12px;
          color: #959595;
        }

        .orderRef {
          font-weight: 400;
          font-size: 12px;
          color: #7435b2;
        }
        .amount {
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }
}
