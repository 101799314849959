@import "../_variables";

.dashboard-wrapper {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

  .sidebar {
    width: 265px;
    height: 100vh;
    display: block;
    transition: 0.2s ease-in-out;

    &.expanded {
      width: 100px;
      padding-top: 500px;

      .sidebar-content {
        width: 100px;
      }
    }

    .sidebar-content {
      transition: 0.2s ease-in-out;
      position: fixed;
      overflow: scroll;
      left: 0;
      top: 0;
      background: $black;
      padding: 32px 24px;
      width: 265px;
      height: 100vh;
      display: block;

      .logo-container {
        .full-logo {
          margin-top: -8px;
          width: 110px;
        }
        .sidebar-toggle {
          position: absolute;
          top: 30px;
          right: 0;
          width: 20px;
          cursor: pointer;
        }
      }

      .navbars {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        .drop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .arrow {
            color: white;
          }
        }

        .navbar {
          display: flex;
          align-items: center;
          padding: 16px;
          gap: 8px;

          &.sub {
            padding-left: 44px;
          }

          h6 {
            text-decoration: none !important;
            font-size: 14px;
            line-height: 20px;
            color: #999999;

            &.hide {
              display: none;
            }
          }
        }

        .navbar.active {
          background: rgba(255, 255, 255, 0.3);
          border-radius: 8px;

          h6 {
            color: $white;
          }
        }
      }
      .switch-button {
        position: absolute !important;
        bottom: 40px;
        left: 40px;
      }
    }
  }

  .main-section {
    width: calc(100% - 265px);
    position: sticky;
    right: 0;
    top: 0;

    &.expanded {
      width: calc(100% - 100px);
    }

    .header {
      display: flex;
      position: fixed;
      background-color: $landing-page-bg;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      z-index: 100;
      top: 0;
      right: 0;
      width: calc(100% - 265px);
      box-shadow: 1px 0px #eff2f7;
      height: 72px;
      border-bottom: 1px solid rgba(26, 27, 26, 0.1);
      transition: 0.2s ease-in-out;

      &.expanded {
        width: calc(100% - 100px);
        transition: 0.2s ease-in-out;
      }

      .search {
        width: 550px;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 10px;
        // border: 1px solid $text-black-5;
        // border-radius: 8px;

        form {
          margin: 0;
          padding: 0;
          width: 100%;
          display: flex;
          align-items: center;
          input {
            font-size: 12px;
            line-height: 16px;
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background-color: none;

            &::placeholder {
              color: $text-black-4;
            }
          }
        }
      }

      .mobile-logo {
        display: none;
      }

      .notification-wrapper {
        display: flex;
        gap: 40px;
        align-items: center;

        .toggle {
          cursor: pointer;
        }

        .notification {
          position: relative;
          border: 0.5px solid $text-black-5;
          background-color: $white;
          border-radius: 50%;
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            position: absolute;
            top: -3px;
            right: -6px;
            width: 20px;
            aspect-ratio: 1;
            background-color: $primary-green;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $white;
            font-size: 8px;
            font-weight: 700;

            .notification-dot {
              display: block;
            }
          }
        }
        .profile {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          position: relative;

          .profile-pics {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: $black;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: $white;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .profile-menu {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              color: $text-black-1;
              font-size: 16px;
              font-weight: 400;
            }
            img {
              display: block;
              width: 12px;
              height: 12px;
            }
          }

          .menu-wrapper {
            position: absolute;
            padding: 12px 4px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            background-color: $white;
            border-radius: 4px;
            box-shadow: 0px 4px 15px rgba(131, 133, 131, 0.15);
            width: 96px;
            top: 34px;
            left: 21px;

            .menu-bar {
              display: block;
              font-size: 12px;
              font-weight: 500;
              padding: 4px 12px;
              border-radius: 4px;
              color: $text-black-1;

              &:nth-child(2) {
                color: $red;
              }

              &:hover {
                background-color: #ffa05b26;
              }
            }
          }
        }
      }
    }

    .main-contents {
      margin-top: 72px;
      padding: 24px 32px;
      width: 100%;

      h1 {
        font-weight: 700;
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-wrapper {
    .sidebar {
      position: fixed;
      z-index: 100;

      &.expanded {
        padding-top: 100px !important;
        display: none;
      }
    }
  }

  .main-section {
    width: 100% !important;
    position: fixed;

    &.expanded {
      width: 100% !important;
    }

    .header {
      padding: 0 20px !important;
      width: 100% !important;
      &.expanded {
        width: 100% !important;
      }

      .search {
        display: none !important;
      }

      .mobile-logo {
        width: 120px;
        display: flex !important;
        gap: 24px;
        align-items: center;

        .logo {
          width: 100%;
        }

        .hamburger {
          height: 10% !important;
          width: 20% !important;
        }
      }
      .notification-wrapper {
        .profile {
          .profile-menu {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              display: none;
            }
          }

          .menu-wrapper {
            top: 44px !important;
            left: unset !important;
            right: 0px !important;
          }
        }
      }
    }

    .main-contents {
      padding: 20px !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-section {
    width: 100% !important;
    position: fixed;

    &.expanded {
      width: 100% !important;
    }

    .header {
      padding: 0 20px !important;
      width: 100% !important;
      &.expanded {
        width: 100% !important;
      }

      .search {
        display: none !important;
      }

      .mobile-logo {
        width: 120px;
        display: flex !important;
        gap: 12px;
        align-items: center;

        .mobile-logo-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          overflow: hidden;
          .logo {
            width: 100%;
          }
        }

        .hamburger {
          height: 10% !important;
          width: 20% !important;
        }
      }
      .notification-wrapper {
        gap: 10px !important;
        .profile {
          .profile-menu {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              display: none;
            }
          }

          .menu-wrapper {
            top: 44px !important;
            left: unset !important;
            right: 0px !important;
          }
        }
      }
    }

    .main-contents {
      padding: 20px !important;
    }
  }
}
