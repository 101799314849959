@import "../../../ui/_variables";

.cat-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 24px;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  justify-content: unset !important;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }

  .branch-card {
    cursor: pointer;
    flex: 0.33;
    background-color: $white;
    border-radius: 16px;
    height: 150px;
    box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
    padding: 24px;
    transition: 500 ease;

    &:hover {
      opacity: 0.8;
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      img {
        padding: 10px;
        background-color: $black;
        border-radius: 50%;
        width: 38px;
      }

      button {
        font-size: 10px;
        padding: 5px 30px;
      }
    }

  }
}

@media screen and (max-width: 600px) {
  .cards {
    grid-template-columns: 1fr;
    gap: 12px;

    .card {
      height: 150px;
    }
  }
}
