@import "../_variables";

.telephone-input {
  position: relative;

  .PhoneInputInput {
    outline: none;
    border: none;
  }

  ::-webkit-scrollbar {
    background: #f2fff6 !important;
    width: 19px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: $primary-green !important;
    border-radius: 4px;
    width: 15px !important;
  }

  .label {
    color: $text-black-1;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-bottom: 4px;
  }

  .copy-text {
    padding: 8px 14px;
    border-radius: 900px;
    background-color: #e4e3e6;
    font-size: 12px;
    color: #222222;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
  }
  .copy {
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
    width: 16px;
  }

  .tel-input {
    display: flex;
    outline: none;
    padding: 14px 16px;
    width: 100%;
    border-radius: 10px;
    display: block;
    line-height: 17px;
    size: 14px;
    -webkit-appearance: none;
    border: 0.5px solid $text-black-5;
    background: $white;

    &:focus {
      outline: none;
      border-color: $primary-green;
    }

    &-invert {
      border: 1px solid $primary-green;
    }
    &-invert:focus {
      outline: none;
      border-color: $white;
    }
    &-error {
      color: $red;
      font-size: 12px;
    }
    &-error-input {
      border: 0.5px solid $red;
    }
    &-error-input:focus {
      border: 0.5px solid $red;
    }
  }
}

.telephone-input .tel-input:focus ~ .label,
.telephone-input
  .tel-input:not(:placeholder-shown).tel-input:not(:focus)
  ~ .label {
  background-color: $white;
  top: 0;
  left: 28px;
  padding: 0 4px;
  font-size: 12px;
}

.tel-input.undefined.PhoneInput {
  display: flex;
}

.PhoneInputCountryIconImg {
  width: unset !important;
  display: none !important;
}

.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  display: none;
}
