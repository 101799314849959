@import "../_variables";

.settings-layout {
  display: flex;
  gap: 24px;

  .settings-navigation,
  .settings-main {
    background-color: $white;
    border-radius: 25px;
    box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
  }

  .settings-navigation {
    // flex: 3;
    max-width: 600px;
    width: 400px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    .setting-navigation {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-bottom: 1px solid #e5e5e5;

      .title {
        color: rgba(26, 27, 26, 0.6);
      }

      .settings-navs {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $text-black-2;

          .setting-inner-div {
            display: flex;
            align-items: center;
            gap: 10px;

            p{
              font-size: 16px;
              font-weight: 500;
            }
          }

          // &.active {
          //   color: $primary-green;
          // }
        }
      }
    }
  }

  // .settings-main {
  //   flex: 12;
  //   padding: 24px;
  // }
}

@media screen and (max-width: 720px) {
  .settings-layout {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .settings-navigation,
    .settings-main {
      background-color: $white;
      box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
    }

    .settings-navigation {
      width: 100% !important;
      flex: 3;
      display: flex;
      flex-direction: column;
      height: fit-content;

      .setting-navigation {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-bottom: 1px solid #e5e5e5;

        .title {
          color: rgba(26, 27, 26, 0.6);
        }

        .settings-navs {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .link {
            color: $text-black-2;

            // &.active {
            //   color: $primary-green;
            // }
          }
        }
      }
    }

    .settings-main {
      flex: 12;
      padding: 24px;
    }
  }
}
