@import "../_variables";

.layout-container {
  display: flex;

  .left-layout-div {
    width: 520px;
    overflow: hidden;
    display: block;

    .inner-left-div {
      background-color: $text-black-1;
      width: 520px;
      position: fixed;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 200px;
      justify-content: center;

      .background-vector {
        position: absolute;
        top: o;
        left: 0;
        z-index: -1;
      }

      .dropper-logo {
        width: 25%;
      }
    }
  }

  .right-layout-div{
    width: calc(100% - 520px);
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .layout-container {
    .left-layout-div {
      display: none;
    }
  
    .right-layout-div{
      padding: 24px !important;
      width: 100%;
      display: flex;
      // justify-content: center;
    }
  }
}
