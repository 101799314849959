@import "../../../ui/_variables";

@media screen and (min-width: 600px) {
  .product-information {
    .table-details-body {
      .mobile-cards-container {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-information {
    .table-details-body {
      .product-information-header {
        width: 100% !important;
      }
      .product-information-body {
        width: 100% !important;
        .menu-update-button{
            white-space: nowrap;
        }
      }
    }

    .table-details-body {
      .menu-items-information {
        display: none;
      }
      .mobile-cards-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .mobile-card-container {
          border-radius: 8px;
          padding: 8px 12px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          gap: 12px;

          p.type2.bold {
            font-weight: 700;
          }
        }
      }
    }
  }
}
