@import "../../_variables";

.orders-table-section {
  .orders-table {
    .contact {
      color: $primary-green !important;
    }

    .time.green {
      color: $primary-green;
    }
    .time.black {
      color: $text-black-2;
    }

    .status {
      width: fit-content;
      padding: 2px 8px;
      border-radius: 4px;

      &.Assigned, &.OUT_OF_STOCK {
        background-color: rgba(241, 180, 24, 0.15);
        color: #f1b418;
      }
      &.Processing {
        background-color: rgba(29, 53, 136, 0.15);
        color: #1d3588;
      }
      &.Dropped {
        background-color: #2EBB5526;
        color: #2EBB55;
      }
      &.Dropping {
        background-color: rgba(47, 128, 237, 0.15);
        color: #2f80ed;
      }
      &.Picking_up {
        background-color: rgba(29, 53, 136, 0.15);
        color: #1d3588;
      }
      &.Closed, &.CLOSED, &.INACTIVE {
        background-color: rgba(238, 39, 55, 0.24);
        color: #eb5757;
      }
      &.Open, &.ACTIVE {
        background-color: #2EBB5526;
        color: #2EBB55;
      }
    }

    .action {
      display: flex;
      align-items: center;
    }

    .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
      border-radius: 0;
      box-shadow: none;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft,
    .MuiTableCell-root.MuiTableCell-head {
      width: unset !important;
      font-size: 12px !important;
      line-height: 18px !important;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
      color: $text-black-2 !important;
      padding: 8px 8px 8px 0px !important;
      font-weight: 400;
    }

    .MuiTableCell-root.MuiTableCell-head {
      color: $text-black-1 !important;
      padding: 16px 16px 16px 0px !important;
      font-weight: 500;
    }

    .MuiToolbar-root.MuiToolbar-regular {
      display: none;
    }

    .MuiTableCell-root.MuiTableCell-head {
      padding-left: 0;
    }

    .empty-view {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
