@import "../_variables";

.search-input {
  width: 100%;
  display: flex;
  border: 0.5px solid $text-black-5;
  align-items: center;
  border-radius: 10px;
  background: $white;
  padding-left: 12px;

  .icon {
    margin-right: 2rem;
    margin-left: 2rem !important;
  }

  input[type="search"] {
    border: none;
    border-radius: 10px;
    padding: 14px 16px;
    font-size: 14px;
    outline: none;
    width: 100%;
  }

  input[type="search"]::placeholder {
    color: $text-black-4;
  }

  input[type="search"]:focus {
    outline: none;
    border-color: $primary-green;
  }

  .input-shift {
    margin-left: 0.5rem;
  }
}

.search-input-invert {
  // border-color: $primary-color;
}
.search-input-invert:hover {
  border: 1px solid $text-black-5 !important;
  transition: 0.5s;
}
