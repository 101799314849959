@import "../../ui/_variables";

.order-single-page {
  .go-back {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 17px;
    cursor: pointer;
    margin-bottom: 24px;

    h6 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $primary-green;
      white-space: nowrap;
    }
  }

  .page-header{
    p{
      display: none;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: $text-black-1;
  }

  .order-details {
    margin-top: 40px;
    display: block;
    width: 100%;
    border: 1px solid $text-black-5;
    border-radius: 8px;
    padding: 24px 32px;

    .details-navigation {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $text-black-5;

      .navbars {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;

        .navbar {
          font-weight: 400;
          line-height: 17px;
          font-size: 14px;
          color: $text-black-3;
          padding-bottom: 13px;
          padding: 0 12px;
          cursor: pointer;

          &.active {
            font-weight: 500;
            color: $text-black-1;
            border-bottom: 2px solid $primary-green !important;
          }
        }
      }

      .timer {
        span {
          padding: 4px 12px;
          border: 1px solid $text-black-5;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: $text-black-3;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .order-single-page {

    .page-header{
      p{
        display: block !important;
      }
    }

    h2 {
      font-size: 20px;
    }

    .order-details {
      margin-top: 20px;
      border: unset;
      border-radius: unset;
      padding: unset;

      .details-navigation {
        width: 100% !important;
        overflow: scroll !important;
        display: flex;

        .navbars {
          width: 100% !important;
          overflow: scroll !important;
          display: flex;
          align-items: center;
          gap: 20px;

          .navbar {
            font-weight: 400;
            line-height: 17px;
            font-size: 14px;
            color: $text-black-3;
            padding-bottom: 13px;
            white-space: nowrap;

            &.active {
              font-weight: 500;
              color: $text-black-1;
              border-bottom: 2px solid $text-black-1;
            }
          }
        }

        p.order-id {
          display: none;
        }

        .timer{
          display: none !important;
        }
      }
    }
  }
}
