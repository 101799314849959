@import "../../ui/_variables";

.page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .page-header{
        flex-direction: column;
        // gap: 12px;
        align-items: unset !important;
    }
}