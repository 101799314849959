@import "../_variables";

.page-heading {
  margin-bottom: 24px;
  h2 {
    margin-bottom: 8px;
    color: $text-black-1;
  }
  p {
    color: $text-black-3;
  }
}

@media screen and (max-width: 600px) {
  .page-heading {
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #222222;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #9f9f9f;
    }
  }
}
