@import "../../ui/_variables";

.order-single-page {
  .go-back {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 17px;
    cursor: pointer;
    margin-bottom: 24px;

    h6 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $primary-green;
      white-space: nowrap;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: $text-black-1;
  }

  .header-buttons {
    display: flex;
    gap: 10px;
  }

  .store-branch-details {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 5px;

    .store-branch-detail {
      padding: 10px;
      background: $white;
      color: $text-black-1;

      .store-branch-image {
        display: flex;
        gap: 10px;

        img {
          width: 60px;
          //
        }
      }

      // &.images {
      //   display: flex;

      //   .image {
      //     display: block;
      //     width: 44px;
      //     height: 44px;
      //     border-radius: 50%;
      //   }
      // }
    }
  }
}

@media screen and (max-width: 600px) {
  .order-single-page {
    h2 {
      font-size: 20px;
    }

    .header-buttons {
      display: flex;
      gap: 10px;
    }

    .store-branch-details {
      grid-template-columns: 1fr;

      .store-branch-detail {
        .store-branch-image {
          display: flex;
          gap: 10px;

          img {
            width: 60px;
          }
        }
      }
    }
  }
}
