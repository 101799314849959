@import "../../_variables";

.popup {
  .popup-container.successful {
    width: 344px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .close {
      position: absolute;
      top: 41px;
      right: 33px;
    }
    .successful-gif {
      width: 96px;
      margin-bottom: 24px;
    }
    .popup-header.successful {
      text-align: center;
    }
    
  }
}
