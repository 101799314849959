@import "../../../ui/_variables";

.order-details {
  .go-back {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
    width: 17px;

    h6 {
      color: $primary-green;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
  .details-container {
    display: flex;
    flex-direction: column;

    .popup-header{
        margin-bottom: 32px;
    }

    .information-fields{
        // width: 410px;
        border: 1px solid rgba(26, 27, 26, 0.1);
        padding: 24px 32px;
        border-radius: 8px;
        background-color: $landing-page-bg;

        .avatar-container{
            display: flex;
            gap: 24px;
            align-items: center;
            margin-bottom: 32px;
        }

        form{
            width: 410px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    hr.horizontal-line {
      border: 1px solid rgba(26, 27, 26, 0.1);
    }
  }
}

.section-description {
  margin-top: 32px;
  margin-bottom: -16px;

  &.topmost{
    margin-top: 0;
    margin-bottom: 16px;
    width: 410px;
  }
}

.description-text {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 17px;
  font-size: 14px;
}

hr {
  border: 0.5px solid rgba(26, 27, 26, 0.1);
  margin-bottom: 24px;
}
