@import "../_variables";

.dropdown-input {
  position: relative;

  .label {
    color: $text-black-1;
    display: block;
    size: 14px;
    pointer-events: none;
  }

  .Dropdown-control {
    outline: none;
    padding: 14px 16px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    display: block;
    line-height: 17px;
    size: 14px;
    -webkit-appearance: none;
    border: 0.5px solid $text-black-5;
    background: $white;

    .Dropdown-placeholder{
      font-size: 13px;
      color: $text-black-2;
    }

    .Dropdown-arrow {
      margin-top: 7px;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
      border-color: $primary-green;
    }

    &-error {
      color: $red;
      font-size: 12px;
    }

    &-error-input {
      border: 0.5px solid $red;
      padding: 14px 16px;
      width: 100%;
      border-radius: 10px;
      display: block;
      line-height: 17px;
      -webkit-appearance: none;
      size: 14px;
    }
    &-error-input:focus {
      border: 0.5px solid $red;
    }
  }

  .Dropdown-menu {
    margin-top: 8px;
    background: #ffffff;
    border: 0.5px solid rgba(26, 27, 26, 0.2);
    box-shadow: 0px 4px 15px rgba(131, 133, 131, 0.15);
    border-radius: 4px;
    padding: 14px 8px;

    .Dropdown-option{
      border-radius: 4px;

      &.wider{
        width:"200px" !important;
      }

      &:hover{
        background-color: #F5F5F5;
      }

      &.is-selected{
        background-color: #F5F5F5;
      }
    }
  }
}

.Dropdown-placeholder.is-selected ~ .label {
  background-color: $white;
  top: -7px;
  left: 28px;
  padding: 0 4px;
  font-size: 12px;
}

.dropdown-input .Dropdown-control:focus ~ .label,
.dropdown-input
  .Dropdown-control:not(:placeholder-shown).Dropdown-control:not(:focus)
  ~ .label {
  background-color: $white;
  top: -7px;
  left: 28px;
  padding: 0 4px;
  font-size: 12px;
}
