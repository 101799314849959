@import "../../../ui/_variables";

.order-information {
  display: flex;
  flex-direction: column;
  width: 100%;

  .order-status {
    border-bottom: none !important;
    p {
      padding: 2px 7px;
      border-radius: 4px;
      width: fit-content;
      &.Assigned {
        background-color: rgba(227, 166, 9, 0.15);
        color: #e3a609 !important;
      }
      &.Dropped {
        background-color: #FFA05B26;
        color: $primary-green !important;
      }
      &.Dropping {
        background-color: rgba(47, 128, 237, 0.15);
        color: #2f80ed !important;
      }
      &.Picking.Up {
        background-color: rgba(29, 53, 136, 0.15);
        color: #1d3588 !important;
      }
      &.Flagged {
        background-color: rgba(238, 39, 55, 0.24);
        color: #eb5757 !important;
      }
    }
  }

  .sender-information, .reciever-information{
    grid-template-columns: 4fr 3fr 5fr !important;
  }

  .droppers-information,
  .transport-partner,
  .item-description,
  .order-status,
  .sender-information,
  .reciever-information {
    display: flex;
    width: 100%;
    // grid-template-columns: 10fr 10fr 10fr 28fr;
    padding: 35px 35px;
    border-bottom: 1px solid $text-black-5;
    gap: 50px;
    justify-content: space-between;

    .detail-information {
      display: flex;
      gap: 16px;
      // align-items: center;

      img {
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .description {
        max-width: 250px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.dropdown{
          width: 250px;
        }

        h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: $text-black-3;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: $text-black-1;
        }
      }
    }
  }
}
