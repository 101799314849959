@import "../_variables";

.error-view {
  text-align: center;
  img {
    width: 120px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  p {
    color: black;
    line-height: 2rem;
  }
  &__retry {
    color: red !important;
    cursor: pointer;
    font-weight: bold;
  }
}
