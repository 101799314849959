@import "../_variables";

.custom-button {
  .button {
    outline: none !important;
    border: none;
    width: 100%;
    background-color: $primary-green;
    color: $white;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 32px;

    &:hover {
      background-color: #f76227;
      color: $white;
      transition: 0.5s;
    }

    &:disabled {
      background-color: #f7622750;
    }
  }

  .button-invert {
    background-color: $white !important;
    border: 1px solid $primary-green !important;
    color: $primary-green !important;
    font-weight: 400;
    outline: none;

    &:disabled {
      border: 1px solid #f7622750 !important;
      color: #f7622750 !important;
    }
  }
  .button-invert:hover {
    background-color: $primary-green !important;
    color: $white !important;
    transition: 0.5s;

    &:disabled {
      background-color: transparent !important;
      border: 1px solid #f7622750 !important;
      color: #f7622750 !important;
    }
  }
}
