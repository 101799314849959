@import "../_variables";

.text-input {
  margin-top: 20px;
  position: relative;

  input.input{
    padding: 10px 16px;
  }

  .loading {
    font-size: 14px;
    line-height: 24px;
    color: $text-black-5;
  }

  label.label{
    position: absolute;
    top: -20px;
  }

  .no-suggestion{
    display: none;
  }

  .suggestions {
    background-color: $white;
    padding: 12px 8px;
    border: 0.5px solid rgba(26, 27, 26, 0.2);
    box-shadow: 0px 4px 15px rgba(131, 133, 131, 0.15);
    border-radius: 4px;
    margin-top: 8px;

    .suggestion{
        padding: 4px 12px;
        font-size: 14px;
        line-height: 24px;
        color: $text-black-1;
        border-radius: 4px;
        cursor: pointer;

        &:hover{
            background-color: #F5F5F5;
        }
    }
  }
}
