@import "../../../ui/_variables";

.wallets-cards {
  display: flex;
  gap: 24px;
  align-items: center;
  flex: 1;
  margin-top: 0 !important;
  width: 300px;

  .card {
    background-color: $white;
    border-radius: 16px;
    height: 189px;
    width: 300px !important;
    box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
    padding: 24px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      i {
        color: $white;
        padding: 10px;
        background-color: $black;
        border-radius: 50%;
        width: 38px;
        aspect-ratio: 1;
        text-align: center;
      }

      button {
        font-size: 10px;
        padding: 5px 30px;
      }
      .filter-wrapper {
        position: relative;
        .dropdown {
          background-color: $landing-page-bg;
          border: 1px solid rgba(26, 27, 26, 0.1);
          padding: 4px 12px;
          border-radius: 4px;
          color: $text-black-1;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          h6 {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
          }

          .arrow-down {
            width: 9px;
          }
        }

        .dropdown-option {
          display: flex;
          flex-direction: column;
          padding: 13px 4px;
          background-color: $white;
          box-shadow: 0px 4px 15px rgba(131, 133, 131, 0.15);
          border-radius: 4px;
          position: absolute;
          top: 35px;
          right: 0;
          z-index: 100;
          width: 96px;

          &.wider{
            width:"200px" !important;
          }

          .dropdown-list {
            padding: 4px 12px;
            cursor: pointer;
            border-radius: 4px;

            h6 {
              width: fit-content;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              color: $text-black-1;
            }

            &:hover {
              background-color: #ffa05b26;
            }
          }
        }
      }
    }

    .description {
      font-size: 14px;
      color: $text-black-4;
      margin-bottom: 8px;
    }
    .detail {
      font-size: 20px;
      color: $text-black-1;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 600px) {
  .cards {
    display: grid !important;
    grid-template-columns: 1fr !important;
    gap: 12px;

    .card {
      height: 150px;
      width: 100%;
    }
  }
}
