@import "../../../../ui/_variables";

.profile-section {
  width: 410px;
  padding: 30px 20px;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
  .avatar-container {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 24px;

    .avatar {
      width: 80px;
      img {
        width: 100%;
      }
    }

    .file-input {
      h3 {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 22px;

    .text-input {
      label {
        font-size: 14px !important;
      }

      .input {
        background-color: #f5f5f5;
        padding: 17px 13px;
        font-size: 16px;
        font-weight: 500;
        border: none;
      }
    }

    .telephone-input {
      label {
        font-size: 14px !important;
      }

      .tel-input {
        background-color: #f5f5f5;
        padding: 17px 13px;
        font-size: 16px;
        font-weight: 500;
        border: none;
      }
    }

    .profile-button {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-section {
    width: 100%;
  }
}
