@import "../../../ui/_variables";

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $text-black-2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 110;
  overflow: scroll;

  &.overlay {
    background: transparent;
  }

  .popup-container {
    width: 400px;
    background-color: $landing-page-bg;
    padding: 40px 32px;
    border-radius: 8px;
    margin-top: 120px;
    position: relative;

    &.trail {
      width: 1024px;
      .close {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      .popup-header {
        margin-bottom: 32px;
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          line-height: 17px;
        }
      }

      .avatar-container {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 16px;

        .avatar {
          height: 44px;
          width: 44px;
          background-color: #ffa05b26;
          border-radius: 50%;

          img {
            width: 100%;
          }
        }
      }

      .order-information {
        .store-branch-details {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 32px;

          .store-branch-detail {
            padding: 16px;
            border-radius: 10px;
            border: 1px solid $text-black-2;
          }
        }
      }

      .add-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .add-button {
          margin-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .popup {
    padding: 12px;
    .popup-container {
      width: 100% !important;
      padding: 32px 20px !important;
      margin-top: 60px !important;
    }
  }
}
