@import "../../../ui/_variables";

.cards {
  &.operation {
    height: unset !important;
    align-items: unset;

    .card{
        height: unset;

        .operation-details{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .details{
                display: flex;
                justify-content: space-between;
            }
        }
    }
  }
}
