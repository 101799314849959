@import "../_variables";

.custom-paginate {
  margin-top: 1rem;
  width: 100%;
  div {
    display: flex;
    float: right;
  }

  .custom-paginate-blocK {
    overflow: hidden;
    width: 1.8rem;
    height: 1.8rem;
    background-color: $white !important;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: smaller;
    font-weight: 400;
    color: $text-black-5;
    border: 0.5px solid $text-black-5;
    cursor: pointer;
  }
  .custom-paginate__current-block {
    border: 0.5px solid $primary-green;
  }
  .custom-paginate__disabled-block {
    color: $text-black-4;
  }
}
