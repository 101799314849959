@import "../../../ui/_variables";

.popup {
  .popup-container {
    form {
      .branch-time-setting {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .time-setting-heading {
          display: flex;
          gap: 12px;
          cursor: pointer;
          width: fit-content;
        }

        .time-settings-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .time-settings-column {
            display: flex;
            gap: 24px;
          }
        }
      }
    }
  }
}
