@import "../../../ui/_variables";

.order-information {
  display: flex;
  flex-direction: column;

  .page-action-container{
    display: none;
  }
  .page-action-bar{
    display: none;
  }

  .order-status {
    border-bottom: none !important;
    p {
      padding: 2px 7px;
      border-radius: 4px;
      width: fit-content;
      &.Assigned {
        background-color: rgba(227, 166, 9, 0.15);
        color: #e3a609 !important;
      }
      &.Dropped {
        background-color: #ffa05b26;
        color: $primary-green !important;
      }
      &.Dropping {
        background-color: rgba(47, 128, 237, 0.15);
        color: #2f80ed !important;
      }
      &.Picking.Up {
        background-color: rgba(29, 53, 136, 0.15);
        color: #1d3588 !important;
      }
      &.Flagged {
        background-color: rgba(238, 39, 55, 0.24);
        color: #eb5757 !important;
      }
    }
  }

  .sender-information,
  .reciever-information {
    grid-template-columns: 4fr 3fr 5fr !important;
  }

  .droppers-information,
  .transport-partner,
  .item-description,
  .order-status,
  .sender-information,
  .reciever-information {
    display: grid;
    grid-template-columns: 5fr 4fr 3fr 2fr;
    padding: 35px 35px;
    border-bottom: 1px solid $text-black-5;

    .detail-information {
      display: flex;
      gap: 16px;
      // align-items: center;

      img {
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.dropdown {
          width: 250px;
        }

        h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: $text-black-3;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: $text-black-1;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .order-information {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .dropper-order-details {
      background-color: white;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      padding: 0 !important;

      .store-branch-detail {
        &.dropper-order-detail {
          background-color: transparent !important;
          padding: 0 !important;
          .description {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            gap: 20px;
            border-bottom: 1px solid #f5f5f5;

            p {
              color: #959595;
              font-size: 14px;
              font-weight: 400;
             
            }
            h5 {
              color: #222222;
              font-size: 14px !important;
              font-weight: 500;
              margin-top: 0 !important;
              text-align: right;
            }
          }
        }
        &.mobile-hidden {
          display: none !important;
        }
      }
    }

    .page-action-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .arrived-action-section {
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        padding: 25px 20px;
      }

      .completed-action-bar {
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        .action-description {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid #f5f5f5;
          font-weight: 500;
          color: #222222;
          font-size: 14px !important;

          .check-mark {
            width: 20px;
            height: 20px;
            background: #2EBB55;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .action-bar {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;

          button {
            border-radius: 900px;
            padding: 8px 54px;
            color: #ffffff;
            font-size: 18px;
            background-color: #2EBB55;
            font-weight: 600;
          }
        }
      }
    }

    .page-action-bar {
      background-color: white;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .action-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #f5f5f5;
        font-weight: 500;
        color: #222222;
        font-size: 14px !important;
      }

      .action-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        button {
          border-radius: 900px;
          border: 1px solid #f1daff;
          padding: 8px 54px;
          color: #7435b2;
          font-size: 20px;
          background-color: transparent;
          font-weight: 600;
        }
      }
    }
  }
}
