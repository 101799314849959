@import "../_variables";

.password-input {
  position: relative;

  .copy{
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
  }

  .label {
    color: $text-black-1;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-bottom: 4px;
  }

  .toggle {
    width: 14.5px;
    position: absolute;
    top: 40px;
    right: 20px;
    cursor: pointer;

    &.copy-toggle{
      right: 60px;
    }
  }

  .input[type='password'] {
    font-family: Verdana;
  }

  .input {
    outline: none;
    padding: 14px 16px;
    width: 100%;
    border-radius: 8px;
    display: block;
    line-height: 22px;
    size: 16px;
    font-weight: 400;
    -webkit-appearance: none;
    border: 0.5px solid $text-black-5;
    background: $white;

    &:focus {
      outline: none;
      border-color: $primary-green;
    }

    &-invert {
      border: 1px solid $primary-green;
    }
    &-invert:focus {
      outline: none;
      border-color: $white;
    }
    &-error {
      color: $red;
      font-size: 12px;
    }
    &-error-input {
      border: 0.5px solid $red;
    }
    &-error-input:focus {
      border: 0.5px solid $red;
    }
  }
}
