@import "../../../ui/_variables";

.popup {
  &.view {
    align-items: unset;
    justify-content: unset;
    padding: 72px 24px;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    overflow: scroll;

    .gallery {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 12px;
      column-gap: 12px;
      row-gap: 12px;
      color: $white;
      position: relative;

      .image {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 10px;
        overflow: hidden;
        position: inherit;

        img {
          width: 100%;
        }

        .action {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $text-black-2;
          display: flex;
          align-items: flex-end;

          .custom-button {
            width: 100%;
            &.action-button {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .close-popup {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    background: $white;
    border-radius: 7px;
    width: 24px;
  }
}

@media screen and (max-width: 600px) {
  .popup {
    &.view {
      .gallery {
        grid-template-columns: 1fr 1fr !important;
      }
    }
  }
}
