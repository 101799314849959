@import "../../../_variables";

.date-filter-wrapper {
  position: absolute;
  background-color: $white;
  border-radius: 8px;
  // padding: 10px 0;
  // width: 100%;
  top: 67px;
  left: 0;
  filter: drop-shadow(0px 4px 15px rgba(131, 133, 131, 0.15));

  .react-datepicker__month-container {
    border: 1px solid #ffa05b26;
    outline: none;
    .react-datepicker__header {
      color: $text-black-1;
      background-color: #ffa05b26;
    }

    .react-datepicker__day.react-datepicker__day--in-range {
      color: $white;
      background-color: $primary-green;
    }
    .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end,
    .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today,
    .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--weekend,
    .react-datepicker__day.react-datepicker__day--in-selecting-range,
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      color: $text-black-1;
      background-color: #ffa05b26;
    }
  }

  .filter-button{
    color: $white;
    padding: 8px 12px;
    background: $primary-green;
    width: 100%;
    cursor: pointer;
  }

  .dropdown-option {
    display: flex;
    flex-direction: column;

    &.wider{
      width:"200px" !important;
    }

    .dropdown-list {
      padding: 10px 10px 10px 24px;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: rgba(46, 187, 85, 0.1);
      }
    }
  }
}
