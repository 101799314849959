@import '../../../ui/_variables';

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $text-black-2;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 110;
    overflow: scroll;
  
    .popup-container {
      width: 472px;
      background-color: $landing-page-bg;
      padding: 40px 32px;
      border-radius: 8px;
      margin-top: 120px;
      position: relative;

      .close{
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        width: 24px;
      }

      .popup-header{
        margin-bottom: 32px;
          h2{
              font-size: 24px;
              line-height: 30px;
          }
          p{
              font-size: 14px;
              line-height: 17px;
          }
      }

      .avatar-container{
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 16px;

        .avatar{
          height: 44px;
          width: 44px;
          background-color: #FFA05B26;
          border-radius: 50%;
          overflow: hidden;

          img{
            width: 100%;
          }
        }
      }

      .add-form{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .add-button{
            margin-top: 24px;

            &.delete{
              background-color: red !important;
            }
        }
      }
    }
  }
