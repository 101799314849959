@import "../../ui/_variables";

.order-filter {
  &.user {
    display: flex;
    gap: 12px;
    align-items: center;
    border-bottom: 1px solid $text-black-5;
    padding-bottom: 14px;

    .left-div {
      display: flex;
      gap: 12px;

      .filter.dropdown {
        display: flex;
      }

      .dropdown-option {
        width: 130px !important;
        display: flex !important;
        left: 0 !important;
        background: $white;
        top: 30px !important;

        &.wider{
          width:"200px" !important;
        }

        .dropdown-list {
          h6 {
            white-space: nowrap;
          }
        }
      }

      .filter-type {
        display: none;

        &.user {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px 14px;
          margin-bottom: -14px;
          cursor: pointer;

          &.clicked {
            border-bottom: 2px solid $text-black-1;
          }

          h6 {
            color: $text-black-4;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;

            &.text-active {
              color: $text-black-1;
            }
          }

          .user-total{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2EBB5526;
            border: 1px solid #2EBB5550;
            border-radius: 16px;
            padding: 4px 8px;

            h6{
              color: #2EBB55;
            }
          }

          .value {
            padding: 1px 4px;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            border-radius: 7px;

            &.All.Orders {
              color: $text-black-1;
              background-color: rgba(17, 35, 55, 0.08);
              border: 1px solid rgba(17, 35, 55, 0.24);
            }
            &.Assigned {
              color: #e3a609;
              background-color: #fef9eb;
              border: 1px solid rgba(227, 166, 9, 0.24);
            }
            &.Processing {
              background-color: #f6f7f9;
              border: 1px solid rgba(29, 53, 136, 0.15);
              color: #1d3588;
            }
            &.Picking_up {
              color: #f28018;
              background-color: #fef5ed;
              border: 1px solid rgba(242, 128, 24, 0.24);
            }
            &.Dropping {
              color: #1d3588;
              background-color: #f6f7f9;
              border: 1px solid rgba(29, 53, 136, 0.24);
            }
            &.Dropped {
              color: $primary-green;
              background-color: #ffa05b26;
              border: 1px solid rgba(46, 187, 85, 0.24);
            }
            &.Flagged {
              color: #eb5757;
              background-color: #feeeef;
              border: 1px solid rgba(238, 39, 55, 0.24);
            }
          }
        }
      }
    }
    .right-div,
    .left-div {
      position: relative;
      .dropdown {
        background-color: $primary-green;
        padding: 4px 12px;
        border-radius: 5px;
        color: $white;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        h6 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
        }

        .arrow-down {
          width: 9px;
        }
      }

      .dropdown-option {
        display: flex;
        flex-direction: column;
        // padding: 13px 4px;
        // background-color: $white;
        box-shadow: 0px 4px 15px rgba(131, 133, 131, 0.15);
        // border-radius: 4px;
        position: absolute;
        // top: 35px;
        top: 0;
        // right: 0;
        right: 80px;
        z-index: 100;
        width: 96px;

        &.wider{
          width:"200px" !important;
        }

        .dropdown-list {
          padding: 4px 12px;
          cursor: pointer;
          border-radius: 4px;

          h6 {
            width: fit-content;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: $text-black-1;
          }

          &:hover {
            background-color: #ffa05b26;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .order-filter {
    padding-bottom: 12px;
    .left-div {
      display: flex;
      gap: 12px;

      .filter {
        &.dropdown {
          display: flex !important;
        }
      }
      .dropdown-option {
        width: fit-content;
        display: flex !important;
        left: 0 !important;
        background: $white;
        top: 30px;

        &.wider{
          width:"200px" !important;
        }

        .dropdown-list {
          h6 {
            white-space: nowrap;
          }
        }
      }

      .filter-type {
        display: none !important;
      }
    }
  }
}
