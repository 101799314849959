@import "../../../../ui/_variables";

.filter-overall-wrapper {
  position: relative;
  right: 0 !important;
  
  //   top: 4rem;
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  // width: 100%;
  // max-width: 24rem;

  .popover-overall-wrapper {
    position: relative;
    right: 0 !important;
    display: flex;
//   flex-direction: column;
//   align-items: right;
//   justify-items: right;

    .popover-button {
      position: absolute;
      width: fit-content;
      right: 0 !important;
      top: -1.5rem;
      display: block;
      padding: 0.2rem 1.5rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      background-color: $primary-green;
      cursor: pointer;
      color: #ffffff;

      :hover {
        color: #ffffff;
      }
    }

    .popover-panel {
      position: absolute;
      right: 0;
      z-index: 10;
      padding: 1rem 1.5rem;
      margin-top: 1.25rem;
      background-color: #ffffff;
      box-shadow: 5px 5px 5px #00000030;
      border-radius: 8px;
      border: 1px solid #00000030;
      width: 100%;
      max-width: 24rem;
      overflow: scroll;
      height: fit-content;
      max-height: 400px;

      .filter-categories{
        display: flex;
        flex-direction: column;
        gap: 24px;

        .filter-category{
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
      }

      //   @media (min-width: 640px) {
      //     padding-left: 0;
      //     padding-right: 0;
      //   }

      //   @media (min-width: 1024px) {
      //     max-width: 48rem;
      //   }
    }
  }
}
