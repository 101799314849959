@import "../_variables";

.text-input {
  position: relative;

  .copy-text {
    padding: 8px 14px;
    border-radius: 900px;
    background-color: #e4e3e6;
    font-size: 12px;
    color: #222222;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
  }

  .copy {
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
    width: 16px;
  }

  .label {
    color: $text-black-1;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-bottom: 4px;
  }

  .input {
    outline: none;
    padding: 14px 16px;
    width: 100%;
    border-radius: 8px;
    display: block;
    line-height: 22px;
    size: 16px;
    font-weight: 400;
    -webkit-appearance: none;
    border: 0.5px solid $text-black-5;
    background: $white;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
      border-color: $primary-green;
    }

    &-invert {
      border: 1px solid $primary-green;
    }
    &-invert:focus {
      outline: none;
      border-color: $white;
    }
    &-error {
      color: $red;
      font-size: 12px;
    }
    &-error-input {
      border: 0.5px solid $red;
      padding: 14px 16px;
      width: 100%;
      border-radius: 10px;
      display: block;
      line-height: 17px;
      -webkit-appearance: none;
      size: 14px;
    }
    &-error-input:focus {
      border: 0.5px solid $red;
    }
  }
}
