@import "../_variables";

.checkbox-input {
  label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
  }

  label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  label span {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $white;
    border: 2px solid $primary-green;
    border-radius: 6px;
    transition: 0.3s background-color;
  }

  .label-invert span {
    border: 1px solid $primary-green;
  }

  label span:after {
    content: "";
    position: absolute;
    display: none;

    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  label:hover span {
    background-color: #ccc;
  }

  label input:checked ~ span {
    background-color: $primary-green;
  }
  label input:checked ~ span:after {
    display: block;
  }

  .label-invert input:checked ~ span {
    background-color: $primary-green;
  }
}
