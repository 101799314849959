@import '../../ui/_variables';

.table-wrapper{
    margin-top: 24px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);
    padding: 24px;
    width: 100% !important;

    .sub-category{
        margin-bottom: 10px;
        border-bottom: 1px solid $text-black-5;
        padding-bottom: 5px;
        font-weight: 700;
    }
}