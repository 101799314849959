@import "../../../../ui/_variables";

.security-container {
  width: 410px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 20px;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 4px 15px rgba(147, 153, 148, 0.15);

  form {
    display: flex;
    flex-direction: column;
    gap: 22px;

    .text-input {
      label {
        font-size: 14px !important;
      }

      .input {
        background-color: #f5f5f5;
        padding: 17px 13px;
        font-size: 16px;
        font-weight: 500;
        border: none;
      }
    }
  }

  .password-container,
  .transaction-pin-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 24px 16px;
    border: 0.5px solid $text-black-5;
    background-color: $white;
    border-radius: 10px;
    cursor: pointer;

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $text-black-2;
    }

    img {
      width: 5px;
    }
  }
}

@media screen and (max-width: 600px) {
  .security-container {
    width: 100%;
  }
}
