@import "../../../ui/_variables";

.wallet-cards {
  display: flex;
  align-items: center !important;
  gap: 24px;
  // justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .cards {
    flex-direction: column;

    .card{
        width: 100% !important;
    }
  }
}
