@import '../_variables';

.pin-input{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    img{
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
        width: 13px !important;
    }
    .pincode-input-text{
        width: 56px;
        height: 48px;
        padding: 16px 23px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-right: 12px !important;
        border-radius: 6px;
        background-color: $white !important;
        border: 0.5px solid $text-black-5 !important;

        // &:nth-child(3){
        //     margin-right: 40px !important;
        // }
        // &:nth-child(6){
        //     margin-right: 0px !important;
        // }

        &:focus, &:active{
            border: 0.5px solid $primary-green !important;
        }
    }
}

